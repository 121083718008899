import React from "react";
import PropTypes from "prop-types";

import "./base.css";
import "./Layout.css";
import "../fonts/fonts.css";
import FretlinkLogo from "./svg-assets/FretlinkLogo";

import "./SignUpLayout.css";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { ArrowLeft24 } from "./svg-assets/Icons";

const SignUpLayout = ({ children, backTitle, backTarget, onBack }) => {
  const { t } = useTranslation();

  const BackLink = ({ children }) =>
    onBack ? (
      <a
        href="#back"
        onClick={event => {
          event.preventDefault();
          onBack();
        }}>
        {children}
      </a>
    ) : (
      <Link to={backTarget ?? "/"}>{children}</Link>
    );

  return (
    <>
      <div className="su-header">
        <div className="su-previous">
          <BackLink>
            <ArrowLeft24 />
            <span>{backTitle ?? t("sign-up.back-to-website")}</span>
          </BackLink>
        </div>
        <div className="su-header-logo">
          <Link to="/">
            <FretlinkLogo />
          </Link>
        </div>
      </div>
      {children}
    </>
  );
};

SignUpLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignUpLayout;
