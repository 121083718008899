import React from "react";
import { graphql } from "gatsby";

import SEO from "../../components/SEO";
import SignUpLayout from "../../components/SignUpLayout";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import { Carrier112, Shipper112 } from "../../components/svg-assets/SignUpIcons";

const SignUpPage = () => (
  <SignUpLayout>
    <SEO title="Formulaire" />
    <div className="su-main fl-blue-context">
      <div className="fl-tag fl-naked-tag">
        <Trans i18nKey="sign-up.index.tag" />
      </div>
      <h2>
        <Trans i18nKey="sign-up.index.title" />
      </h2>
      <div className="su-two-choices">
        <Link to="/sign-up/shipper/" className="su-choice">
          <Shipper112 />
          <p className="su-strong">
            <Trans i18nKey="sign-up.index.choices.shipper.title" />
          </p>
          <Trans parent="p" i18nKey="sign-up.index.choices.shipper.description" />
        </Link>

        <Link to="/sign-up/carrier/" className="su-choice">
          <Carrier112 />
          <p className="su-strong">
            <Trans i18nKey="sign-up.index.choices.carrier.title" />
          </p>
          <Trans parent="p" i18nKey="sign-up.index.choices.carrier.description" />
        </Link>
      </div>
    </div>
  </SignUpLayout>
);

export default SignUpPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
